.footer
  background-color purple-dark
  color white
  padding 40px 20px 90px
  text-align center

  &-item
    margin-bottom 25px

    a, h6
      color white

    h6
      margin-bottom 20px
      a
        font-weight 600

    a
      font-weight 100

    &.logo
      text-align center

      img
        max-width 140px

    &.work
      .fa-phone
        margin 3px
        font-size 18px

    &.social
      text-align center
      .fa
        margin-right 15px

  @media (min-width: responsive-breakpoint)
    text-align left
    padding 50px 0px 90px

    .wrapper
      display flex
      justify-content space-around

    &-item
      width 16.66%
      margin-bottom 0px
