// Parte de: Animate CSS de Dane Den
// https://github.com/daneden/animate.css
@keyframes fadeInLeft
  from
    opacity 0
    transform translate3d(-120px, 0, 0)

  to
    opacity 1
    transform none

@keyframes fadeInRight
  from
    opacity 0
    transform translate3d(120px, 0, 0)

  to
    opacity 1
    transform none

@keyframes fadeInBottom
  from
    opacity 0
    transform translate3d(0, 30px, 0)

  to
    opacity 1
    transform none

@keyframes fadeInTopSmall
  from
    opacity 0
    transform translate3d(0, -30px, 0)

  to
    opacity 1
    transform none

@keyframes fadeIn
  from
    opacity 0
  to
    opacity 1


// Animations classes
// ------------------------------------
animations = fadeInLeft fadeInTopSmall fadeIn fadeInBottom fadeInRight

.animate
  animation-duration 1s
  transform-origin: center bottom;
  animation-fill-mode: both
  &--long
    animation-duration 2s

  for a in animations
    name = unquote(a)
    &--{name}
      animation-name name

.tooltip-green
  .tooltip-inner
    background-color #137109 !important

  .tooltip-arrow
    border-top-color: #137109 !important
    border-bottom-color: #137109 !important

