.site-footer-countdown
  padding: 5px 0 7px
  display: flex
  align-items: center
  justify-content center

  @media (min-width: 1110px)
    padding: 0px

.countdown
  text-align center
  display flex
  align-items center
  justify-content center

  &.title
    padding: 0px 10px 5px

  &-item
    color white
    padding 0px 11px
    margin-right 7px

  .wrapper
    display flex
    align-items center

    h6, span, p
      font-weight bold
      color white

    h6
      margin-bottom 0px
      font-size 14px
      line-height 1
      letter-spacing 1px

    span
      font-size 24px
      line-height 1

    p
      font-size 10px

  @media (min-width: 1024px)
    padding 0px

    &-item
      margin-right 10px

      &:last-child
        margin-right 0px

    .wrapper
      span
        font-size 26px

      p
        font-size 13px
