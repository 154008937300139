@font-face {
    font-family: "gotham-bold";
    src: url('../fonts/gotham/GothamRnd-Bold.otf');
}

@font-face {
    font-family: "gotham-medium";
    src: url('../fonts/gotham/GothamRnd-Medium.otf');
}

@font-face {
    font-family: "gothamhtf-book";
    src: url('../fonts/gotham/GothamHTF-Book.otf');
}

@font-face {
    font-family: "gothamhtf-bold";
    src: url('../fonts/gotham/GothamHTF-Bold.otf');
}
