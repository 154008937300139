body.page-sponsors  
  .Container
    >.wrapper
      max-width 1200px
      
      .Content
        h2.shop-title
          font-weight bold
          text-align left

.product-list
  .product-title,
  .product-amount
    display inline-block

  .product-title
    margin-right 8px

  .product
    margin-bottom 20px

  // Se construye la grid utilizando flotantes para que los altos de cada
  // producto no sean igualdos por flex
  @media (min-width: 900px)
    .product
      width 50%
      padding 10px 0 15px
      margin-bottom 0
    .product:nth-child(2n+1)
      float left
      padding-right 50px
    .product:nth-child(2n+2)
      float right

.Content .product-list
  list-style-type none
  margin 1em 0 5em

.Content h1.shop-title
  margin-bottom 40px

.Content
  .shop
    &-description
      text-align justify
      font-size 20px
      margin-bottom 2em !important

// Variaciónes
.Container--opportunities .product-list
  .product-title,
  .product-amount
    display block

  .product-title
    margin-bottom 16px
    margin-right 0
    color green-light

  .product-amount
    color grey-darker
    font-weight 800

  .product-description
    margin-top 10px

  @media (min-width: 900px)
    .product-title
      font-size: 2.0rem;
      line-height: 1.1;

    .product-amount
      font-size 1.5rem


.Container--premium-opportunities
  .product-list .product
    transition all 250ms ease
    border 1px solid grey-lighter
    margin-bottom 15px

    .product-description
      p, span, a
        margin-left 10px

      li
        font-size 1rem

      ul
        margin 1em 1.4em 1em 2em

    &:hover
      background-color primary
      color white
      border 1px solid primary
      box-shadow 0 3px 7px rgba(black, .25)
      z-index 1

      .product-title
        background-color primary
        color white

      li, p, span, a, strong
        color white

  @media (min-width: 900px)
    >.wrapper
      max-width 1300px
    .product-list
      display: flex;
      justify-content: center;

    .product-list .product
      width 20%
      padding 0
      margin 0px

      &:hover
        transform: scale(1.05);

    .product
      float none !important

    .product-title
      font-size: 1.4rem !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      min-height: 85px;
      text-align: center;

    .product-description
      li
        font-size 0.9rem !important

  .checkbox
    padding-left 0
    display block

  .checkbox > input
    display none

  .product-amount
    text-align center

  .product-list .product-title
    color grey-darker
    margin-bottom 0

  .product-title
    transition all 250ms ease
    padding 1rem

  .product-amount
    background-color #f6f6f6
    padding 10px

  // Variaciones de productos
  .product
    display block
    padding-bottom 14px
    margin 0px
    overflow hidden
    border-bottom 10px solid white
    transition border-bottom 200ms ease-in-out

    // Diamond
    &:nth-child(5)
      .product-title
        background-color primary
        color white

    // Platinum
    &:nth-child(4)
      .product-title
        background-color #e5e4e2

    // Gold
    &:nth-child(3)
      .product-title
        background-color #fed700

    // silver
    &:nth-child(2)
      .product-title
        background-color #b1b1b1

    &:nth-child(1)
      .product-title
        background-color #cd7f32

  input:checked + .product-label .product-container
    border-bottom-color green

.Container--sponsorship, .Container--opportunities
  .product
    &-container
      &:hover
        .product-few
          display none

.circle-left
  position absolute
  top -3px
  right -3px
  background-color #cb2026
  color white
  width 70px
  height 70px
  border-radius 50%
  padding 10px
  font-size 13px
  text-align center
  line-height initial
  display flex
  align-items center
  font-weight bold

.space-available
  position: absolute;
  top: 11px;
  right: 10px;
  color: white;
  max-width: 70px;
  text-align: center;
  line-height: 1.2;

// Products sold
.product-sold
  background url('/assets/images/sold.png') center no-repeat
  position absolute
  top 0
  left 0
  right 0
  bottom 0

// Christmas boot
.christmas-boot
  background url('/assets/images/christmas-boot.gif') center no-repeat
  background-size: 100%
  position: absolute;
  left: 84%;
  bottom: 65%;
  width: 40px;
  height: 40px;
  @media(min-width: responsive-breakpoint)
    left: 353px;
    background-size: 70% 50%;
    bottom: 62px;
    width: 80px;
    height: 90px;

.product-few
  position absolute
  top: 25px;
  left: 0px;
  right: 0px;
  bottom: -10px;
  background-size: 115px;

  @media (min-width: 900px)
    background-size: 140px;

  &-spaces
    background url('/assets/images/few-spaces.png') center no-repeat

  &-one
    background url('/assets/images/one-left.png') center no-repeat

  &-two
    background url('/assets/images/two-left.png') center no-repeat

  &-five
    background url('/assets/images/five-left.png') center no-repeat

  &-eight
    background url('/assets/images/eight-left.png') center no-repeat

.floor-plans
  position: absolute;
  right: 2%;
  top: 0%;
  background url('/assets/images/plans.png') center no-repeat
  background-size 35px
  width 35px
  height: 35px
  pointer-events: visible

.shop
  .logos
    display: flex
    align-items: center
    justify-content: center
    text-align: center

    a
      width 50%
      padding: 5px

  @media (min-width: normal-breakpoint)
    .logos
      position: absolute
      top 35%
      margin auto
      left 150%
      width: 350px;
      height: auto;
      display: block;

      a
        width 100%
