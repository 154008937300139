.Content
  padding 10px 8px 16px // Para separar grid-items
  &.is-medium
    font-size: size-5
    code
      font-size: size-6
  &.is-large
    font-size: size-4
    code
      font-size: size-5
  &:not(:last-child)
    margin-bottom: 20px
  h1,
  h2,
  h3,
  h4,
  h5,
  h6
    font-family family-primary
    font-weight 400
    color: text-strong
    line-height: 1.125
    margin-bottom: 20px
  h1,
  h2,
  h3
    &:not(:first-child)
      margin-top: 40px
  h1
    font-size: 1.8em
  h2
    font-size: 1.8em
    color purple
  h3
    font-size: 1.8em
  h4
    font-size: 1.5em
  h5
    font-size: 1.3em
  h6
    font-size: 1em

  p:not(:last-child)
    margin-bottom: 1em
  li + li
    margin-top: 0.25em
  ol
    list-style: decimal outside
    margin: 1em 2em
  ul
    list-style: disc outside
    margin: 1em 2em
    ul
      list-style-type: circle
      margin-top: 0.5em
      ul
        list-style-type: square
  blockquote
    background: background
    border-left: 5px solid border
    padding: 1.5em
    &:not(:last-child)
      margin-bottom: 1em

  .comment
    text-align justify
    font-size 14px
    font-style: italic;

  @media (max-width: responsive-breakpoint)
    width 100% !important

  @media (min-width: responsive-breakpoint)
    h1
      font-size: 2.4em
    h2
      font-size: 2.4em
    h3
      font-size: 2.4em
    h4
      font-size: 1.5em
    h5
      font-size: 1.4em
    h6
      font-size: 1em

// Ancho máximo para todos los container
.Container
  width 100%
  padding 20px

  &--full
    padding-left 0
    padding-right 0
    > .wrapper
      max-width 100%

  &--full-height
    padding-left 0
    padding-right 0
    padding-top 0px !important
    min-height initial
    > .wrapper
      max-width 100%
      height 100%

  &--huge
    > .wrapper
      max-width 1320px

  &--big
    > .wrapper
      max-width 1100px

  &--video

    > .wrapper
      display flex
      flex-flow row wrap
      align-self center
      z-index 2
      margin 20 auto
      padding-top 20vh

  &--video-header

    .Content
      border-radius 5px
      color primary
      margin-bottom 10px

      p
        margin-bottom 0px
        padding 0px
        line-height: 0;

      h1
        margin 10px 0px 0px

      h1, h2, h3, h4, h5, h6, p, span, strong, a
        color primary

  @media (min-width: 480px)
    &--video
      > .wrapper
        padding-top 30vh

  @media (min-width: 600px)
    &--video
      > .wrapper
        padding-top 40vh

  @media (min-width: 730px)
    &--video
      > .wrapper
        padding-top 50vh

  @media (min-width: 870px)
    &--video
      > .wrapper
        padding-top 61vh

  @media (min-width: 1024px)

    &--video
      > .wrapper
        padding initial

    &--video-header

      .Content
        color white

        p
          margin-bottom 1em
          line-height 1.42

        h1, h2, h3, h4, h5, h6, p, span, strong, a
          color white

    &:last-child:not(.Container--full-height):not(.consulting-footer)
      padding-bottom 60px

    .Content
      padding-left 20px
      padding-right 20px

.image--background
  width 100%
  background-repeat no-repeat
  background-position 50%
  background-size cover
  height 42vw

  &.carousel
    height 500px

  &.small
    height 34vw
    min-height 180px

  @media (min-width: responsive-breakpoint)
    height 43.5vw
    min-height 43.5vw

    &.small
      height 13vw
      min-height 250px

  @media (min-width: 1300px)
    height 43.5vw
    min-height 43.5vw

body:not(.logged-in) .Container
  position relative // En admin mode rompe el posicionamiento del toolbar
  display flex
  align-items center
  padding-top 90px
  padding-bottom 40px
body:not(.logged-in) .Container:not(.event-division)
  justify-content center
// Ancho maximo Containers en admin mode
body.logged-in
  .Container
    margin 0 auto
    padding 5%

  .background
    &--parallax
      &:after
        content none !important

#travel_pr
  padding 0px !important

#consulting, #home, #prmedcann

  .wrapper
    z-index 1

  &:after
    content ''
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    background-color rgba(black, 45%)
    z-index 0

#espacioblanco
  @media (max-width: 1023px)
    display none

#testimonial
  padding 40px 20px

#consulting_footer
  padding 40px 20px

  .Content
    margin-bottom 0px

#services
  padding 50px 20px 20px

  .Content
    margin-bottom 0px

  @media (max-width: 800px)
    table
      width 100% !important

      img
        height auto

#who_we_are
  padding 40px 20px 10px

  @media (max-width: 800px)
    table
      width 100% !important
      padding 5px

      p
        margin-bottom 0px

      img
        height auto

  @media (min-width: 1024px)
    .Content
      padding-left: 36px;
      padding-right: 35px;

      &:first-child, &:nth-child(2)
        > *:first-child
          border-top 3px solid grey-white

#medios
  padding 0px

  .ImageList
    margin-bottom 0px

#scope_2
  .Content
    margin-bottom 10px

#carousel
  padding 30px 0px

#countdown
  padding 20px
  background-color primary
  width 100%

  .reserve
    text-align center
    margin-top 10px

  @media (min-width: responsive-breakpoint)
    padding 25px
    display flex
    align-items center
    justify-content space-around

    .site-footer-prhempbiz
      img
        max-width 500px

#concept

  > .wrapper
    width 100%
    max-width 100%

    .ImageList-slider
      height 100%
      margin-bottom 0px

      .owl-stage-outer, .owl-stage, .owl-item, .Image-wrapper, .image--background
        height 100%

  @media (max-width: 1024px)
    .ImageList-slider
      display none

  @media (min-width: 1024px)
    padding 0px

    > .wrapper
      .Content
        padding 40px 50px 40px 60px

#header_alivio
  background-image url(../images/alivio-prmcb.jpg)
  background-size cover
  height 42vw
  min-height 42vw
  background-position 0 0

  .logo-ong
    position: absolute
    top 17%
    left 10%
    width 20vw

  .alivio-copy
    position: absolute
    top 40%
    right 7%
    width 48%

    h1
      font-size 4vw
      color rgba(white, 85%)
      font-weight 100
      line-height 1.2
      text-align center

// Background
// ------------------------------------------------
.background
  background-size auto
  background-postion center center
  background-repeat no-repeat

  &-video
    position absolute
    right 0
    top 0
    width 100vw
    height auto

  &-video-left
    position absolute
    right 0
    bottom 0
    min-width 100%
    min-height 100%
    z-index 0

  &-video-right
    position absolute
    right 0
    top 0
    min-width 100%
    height 100%
    z-index 0

  @media (min-width: 1024px)
    background-size cover
    background-position center center

    &-video
      position absolute
      right 0
      top 0
      width initial
      height initial
      min-width 100%
      min-height 100%

    &-video-right
      top 0
      left 0
      min-height 100%

  &--sponsors
    background-image url(../images/bg-sponsors.png)
    background-size cover
    height 42vw
    min-height 42vw
    background-position 0 0

    .copy
      position: absolute
      top 38%
      right 2%
      width 56%

      h1
        font-size 4.5vw
        color white
        font-weight bold
        line-height 1.2
        text-align center

      h2
        font-size 2vw
        color rgba(white, 85%)
        font-weight 500
        line-height 1.2
        border-bottom 2px solid rgba(white, 85%)
        text-align center

  &--logos
    .wrapper
      > div
        &:first-child
          h1, h2, h3, h4
            font-family Lato
            color black
        &:nth-child(2)
          padding 10px
          .Image-wrapper
            display flex
            align-items center
            .Image-linked
              margin auto
            img
              width initial
              margin auto

    @media (min-width: normal-breakpoint)
      min-height initial
      padding-bottom 0px !important

      .wrapper
        > div
          &:first-child
            width 35%
            display flex
            align-items center
            margin-bottom 0px
            h1, h2, h3, h4
              margin-bottom 0px
            h2
              font-size 4.6em
              font-weight 300
              margin auto
          &:nth-child(2)
            width 65%

            .ImageList-carrusel
              padding 0 30px

    @media (min-width: 1300px)
      .wrapper
        > div
          &:first-child
            h2
              font-size 5.2em

  &--white
    background-color body-background

  &--parallax
    color grey-darker

    .wrapper
      z-index 1

    &:after
      content ' '
      position absolute
      top 0px
      left 0px
      width 100%
      height 100%
      background-color rgba(white, 60%)

    .Content
      z-index 1
      color grey-darker

      h1, h2, h3, h4, h5, h6, li, span, strong, a, p
        color grey-darker

.event-division
  @media (min-width: responsive-breakpoint)
    &.Container
      justify-content: initial !important;
    .wrapper
      margin inherit !important
    .Content
      h1, h2, h3, h4, h5, h6, li, span, strong, p
        font-family family-primary
        letter-spacing 0.7px !important

body.page-inicio:not(.logged-in) .ContainersList
  margin-top -70px

.island
  border-radius radius
  padding 20px 26px

  &--green
    background-color green
    color white
    h2,
    h3
      color white
    strong
      color yellow
    .button--ghost
      &:hover,
      &:focus
        color yellow
        border-color yellow

body:not(.logged-in)
  .ImageList-carrusel
    .owl-stage
      display flex
      align-items center

.ImageList-slider
  .owl-prev, .owl-next
    background-color rgba(primary, 80%)
    border-radius 50%
    border 2px solid white
    line-height initial

body.page-event, body.page-past-events
  .ImageList-carrusel
    .Image-wrapper
      .Image-linked
        display: block
        padding: 8px
        img
          width auto
          margin: 0 auto

  @media (min-width: responsive-breakpoint)
    .ImageList-carrusel
      .Image-wrapper
        .Image-linked
          padding: 20px

body.page-past-events
  .ImageList-carrusel:last-child
    img
      width: auto

// Variacion
.tickets-island
  @media (min-width: responsive-breakpoint)
    max-height 220px
    margin-top 65px

// Ajuste tickets en moviles
#tickets
  @media (max-width: responsive-breakpoint)
    text-align center

// Galería
.ImageList-galeria .Image-wrapper
  text-align center
  display flex
  align-items center

  &.Grid-item
    &--third
      width 50%

  img
    margin 0 auto

  @media (min-width: responsive-breakpoint)
    &.Grid-item
      &--third
        width 33.33%

.announces
  background-color rgba(purple, 0.9)
  color white
  border-radius 5px
  padding 25px 10px

  h1, h2, h3, h4, h5, h6, p, a, span, strong
    color white

  p
    font-size 2em

  &:hover
    background-color rgba(#652479, 0.9)

  @media (min-width: responsive-breakpoint)
    margin 0 50px
    padding 40px 30px

    p
      font-size 3em


.Parallax
  height auto
  max-width none !important
  padding 0 !important

  .background
    background-size cover

@media (min-width: 800px)
  .parallax--fixed
    background-attachment: fixed !important;

.Event
  &-Single-data
    z-index 0

.image-rounded
  width 300px
  height 300px
  margin auto
  border-radius 50%
  background-position center
  background-size cover
  background-repeat no-repeat
  @media (min-width: responsive-breakpoint)
    width 400px
    height 400px

@media (max-width: responsive-breakpoint)

  .hide-responsive
    display: none !important

.align-flex
  display: flex;
  align-items: center;
  justify-content: center;
  
.elevatezoom-parent
  position relative
  &:after
    content: '';
    display: block;
    width: 33px;
    height: 33px;
    position: absolute;
    top: 0;
    right: 0;
    background: url(https://raw.github.com/jackmoore/zoom/master/icon.png);
