.popup-patients
  display flex
  align-items center
  width 300px
  height 300px
  padding-top 5px
  position: relative

  &-body
    position absolute
    top 29%
    left 6%
    color white
    max-width 70%
    text-align left

    .first

      span:first-child, span:nth-child(3)
        font-weight 800
        font-size 1.5em
        color white
        letter-spacing -2px

      span:nth-child(2), span:nth-child(4)
        font-weight 800
        font-size 1.1em
        color green-apple
        letter-spacing -2px

      span:last-child
        font-weight bold
        margin-left 10px
        font-size 0.8em
        color white

    h2
      font-size 1.7em
      line-height 0.9
      font-weight 800
      margin 3px 0

    .last
      font-weight bold

    .button-popup
      border-radius 0px
      background-color green-apple
      color purple
      margin-top 8px
      font-weight 500
      box-shadow: 1px 3px 5px 1px rgba(0,0,0,.4)
      border-radius: 20px

  @media (min-width: 350px)
    width 330px
    height 330px

    &-body
      max-width 64%

      h2
        font-size 1.9em

  @media (min-width: normal-breakpoint)
    width 550px
    height 550px

    &-body
      max-width 60%
      top 30%

      .first

        span:first-child, span:nth-child(3)
          font-size 2.8em

        span:nth-child(2), span:nth-child(4)
          font-size 2.2em

        span:last-child
          font-size 1.1em
          margin-left 15px

      .last
        font-size 1.7em

      h2
        font-size 3em
        margin 7px 0

    .button-popup
      margin-top 12px
      font-size 1.4em
      padding 5px 30px
      height 42px
      line-height 32px
