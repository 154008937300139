body.page-travel-to-puerto-rico.logged-in
  .Container
    margin-bottom 60px !important

body.page-travel-to-puerto-rico:not(.logged-in)
  .ImageList
    margin-bottom 0px

  .Container
    .wrapper
      .Content
        padding 20px
        display flex
        align-items center
        flex-flow row wrap
        justify-content: space-around;
        margin-bottom 0px

        p
          margin-bottom 10px

        .button
          white-space initial
          height initial
          line-height initial
          display block
          max-width 230px
          padding 10px 20px

  @media (min-width: 1000px)
    .Container
      padding-bottom 0px !important
      .wrapper
        > div:nth-child(3)
          position: fixed
          bottom: 105px;
          z-index: 1;

        .Content
          padding 5px
          width: 71%;
          z-index: 1;
          position: absolute;
          top: 2%;

          p:nth-child(3)
            .button
              max-width 380px

  @media (min-width: 1100px)
    .Container
      .wrapper
        > div:nth-child(3)
          bottom: 50px;

  @media (min-width: 1200px)
    .Container
      .wrapper
        .Content
          top 12%
