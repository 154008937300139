.ImageList-carrusel
  .owl-item
    .year
      position absolute
      top 10px
      right 10px
      z-index 2
      font-size 34px
      color white
      font-weight bold
      text-shadow 1px 1px grey-dark

body.home
  .ImageList-carrusel
    .owl-item
      .year
        display none
