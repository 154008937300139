
body.logged-in .site-header
  position relative

.site-header
  display flex
  position relative
  z-index 5
  background-color rgba(white, .8)
  transition background-color 520ms ease-in-out
  justify-content space-between
  padding 15px 2%
  box-shadow 0 1px 8px rgb(152 141 41)

  .logo
    width 135px

  .wrapper
    justify-content flex-end

  .logo-alivio
    margin-right 10px
    img
      height 60px

  &.scrolling
    background-color rgba(white, 0.94)
    box-shadow 0 2px 4px rgba(0, 0, 0, 0.1)
    justify-content space-between
    display flex
    padding-top 5px
    padding-bottom 5px


    
  @media (min-width 360px)
    padding-top 0
    padding-bottom 0
    height 70px
    display flex

  @media (min-width 1270px)
    padding 15px 5%
    .wrapper
      width 100%

  @media (min-width 1400px)
    padding 15px 6%
