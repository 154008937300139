
.navigation-item
  margin 0 5px
  font-size 15px

  @media (min-width: 1060px)
    margin 0 12px

  @media (min-width: 1400px)
    margin 0 18px

  &:last-child
    margin-right 0

  > a:not(.button)
    color grey-darker
    text-transform capitalize
    font-size 18px
    font-weight 400
    
    &.underline
      text-decoration underline

    &:hover
      text-decoration underline

.site-header .site-navigation
  display none

.modal
  .site-navigation
    .navigation-item
      > a:not(.button)
        color white
        text-transform uppercase

      > .button
        background-color white
        color primary

.nav-assistive
  display: block;
  height: 32px;
  line-height: 20px;
  margin-top: 15px

@media (min-width: 1270px)
  .site-header .site-navigation
    display block
    .wrapper
      justify-content initial

  .nav-assistive
    display none

  .site-navigation
    height 70px

  .navigation

    &-menu
      height 100%

      > .navigation-item
        height 100%
        display flex
        align-items center

    &-submenu
      position: absolute
      display block
      background-color rgba(white, .8)
      top 100%
      padding 0 10px 10px
      transition all .5s ease;
      top 120%
      visibility hidden
      opacity 0

      .navigation-item
        text-align left
        padding 7px 0px
        margin-right 7px

    &-item
      &:hover
        .navigation-submenu
          opacity 1
          top 100%
          visibility visible

.site-header
  &.scrolling
    .navigation-submenu
      background-color rgba(white, .94)

// Auxiliar nav
.nav-aux-container
  font-size 1.2rem
  text-transform uppercase
  .navigation-menu
    display block
  .navigation-item
    margin 26px 15px
    .button
      padding 4px 10px
  .item-link,
  .item-link:hover
    color white
  .button--ghost
    background-color white

body:not(.logged-in)
  .Breadcrumb
    padding-top 10px

.Breadcrumb
  display block
  max-width 980px
  margin 0 auto
