.Hero > .wrapper
  background-color rgba(248, 247, 242, 0.8)
  padding-top 20px
  padding-bottom 20px

.Hero .Content
  text-align center
  h1
    font-family family-primary
    margin-bottom 0
    word-break break-all

.hero-meta
  margin 10px auto 40px !important
  color grey-dark
  font-size 1.2rem
  text-transform uppercase
  letter-spacing 14px
