form
  .c-label
    label
      font-family family-primary

  .c-forms
    &-confirmation-message
      p
        font-size 22px !important
        font-family family-primary
