
.text-center
  text-align center

.text-right
  text-align right

.text-left
  text-align left

.text-justify
  text-align justify

.clearfix
  *zoom 1
  &:after
  &:before
    content " "
    display table
  &:after
    clear both

.text-hide
  font 0/0 a
  color transparent
  text-shadow none
  background-color transparent
  border 0

screen-reader-text()
  clip rect(1px, 1px, 1px, 1px)
  position absolute
  &:focus
    clip auto
    display block
    height auto
    line-height normal
    position absolute
    left 5px
    top 5px
    text-decoration none
    text-transform none
    width auto
    z-index 100000

.screen-reader-text
  screen-reader-text()

.pull-right
  float right

.pull-left
  float left
