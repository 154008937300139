.cards
  z-index 1
  display flex
  align-items center
  justify-content center
  width 100%

  .card
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color white
    border 1px solid grey-lighter
    border-radius 20px
    padding 20px
    text-align center
    margin-bottom 20px
    min-height 310px
    max-width 400px

    h2, h3
      color primary
      font-size 1.4em
      line-height 1.2
      margin-bottom 15px

    img
      max-width 280px

    .button
      padding: 4px 10px;
      font-size: 18px;

  @media (min-width: 1024px)
    position absolute
    bottom -200px
    left 0px

    .wrapper
      max-width 1250px
      margin auto
      display: flex
      align-items center
      justify-content space-around

    .card
      padding 30px
      width 31%
      height 340px
      max-width initial
