.tickets
  display: flex;
  max-width: 1200px;
  margin: auto;
  align-items: center;
  justify-content: center;

.pre-order
  margin-bottom 20px
  max-width 1200px
  margin auto

  &-wrapper
    display: flex;
    flex-direction: column;

    > div
      margin-bottom: 20px;

      &:first-child
        order 3

      &:nth-child(2)
        order 1

      &:nth-child(3)
        order 2

  &-access
    position relative
    display flex
    > div
      padding 20px
      text-align center
      border-radius 20px
      border 1px solid grey-lighter
      z-index 1
      min-height 340px
      display flex
      flex-direction column
      justify-content space-between
      width 100%

      > *:first-child
        font-size: 1.4em;

      > *:nth-child(2)
        font-size 1.4em
        line-height 1.2
        min-height 100px
        margin-bottom 0px

      > *:nth-child(3)
        font-size 1.6em
        padding 15px
        margin-bottom 0px

    &.inactive
      &:after
        content ''
        position absolute
        top 0px
        left 0px
        width 100%
        height 100%
        background-color grey-lighter
        border-radius 20px
        z-index 0

      .button
        opacity 0.3

  @media (min-width: 1024px)

    &-wrapper
      display flex
      flex-direction initial
      align-items center

      > div
        &:first-child
          order 3

        &:nth-child(2)
          order 1

        &:nth-child(3)
          order 2

    &-access
      width 31%
      margin 15px
      min-height: 400px;

      > div
        padding 25px
        min-height 380px
