
.shop-payment
  position fixed
  right 0
  bottom 200px
  z-index 5

  @media (min-width 1110px)
    bottom 140px

.payment-button
  .fa-shopping-cart
    margin-right 15px
    padding-right @margin-right
    border-right 1px solid darken(primary, 40%)
    display inline-block
    width inherit
