// FORMS
// ============================================================================

$input-control
  -moz-appearance: none
  -webkit-appearance: none
  background: white
  border: 1px solid input-border
  border-radius: radius
  color: grey-dark
  display: inline-block
  font-size: size-normal
  height: 36px
  line-height: 25px
  padding: 3px 8px
  position: relative
  vertical-align: top
  &:hover
    border-color: input-border-hover
  &:active,
  &:focus
    border-color: input-border-focus
    outline: none
  &[disabled]
    &,
    &:hover
      background: grey-lighter

$input
  @extends $input-control
  box-shadow: inset 0 1px 2px rgba(black, 0.1)
  display: block
  max-width: 100%
  width: 100%

input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"]
  @extends $input

textarea
  @extends $input
  line-height: 1.2
  max-height: 600px
  max-width: 100%
  min-height: 120px
  min-width: 100%
  padding: 10px
  resize: vertical


.select
  display: inline-block
  height: 32px
  position: relative
  vertical-align: top
  select
    @extends $input-control
    cursor: pointer
    display: block
    outline: none
    padding-right: 36px
    &::ms-expand
      display: none
  &:after
    arrow(link)
    margin-top: -6px
    right: 16px
    top: 50%
  &:hover
    &:after
      border-color: link-hover

.select--block
  display block
  select
    width 100%

.form-field
  margin 0 auto 7px

.checkbox,
.radio
  cursor: pointer
  display: inline-block
  line-height: 24px
  padding-left: 30px
  position: relative
  vertical-align: top
  input
    @extends $input-control
    box-shadow: inset 0 1px 1px rgba(black, 0.1)
    cursor: pointer
    float: left
    height: 22px
    left: 0
    outline: none
    padding: 0
    position: absolute
    width: 22px
    &:after
      opacity 0
      position: absolute
    &:checked
      box-shadow: none
      &:after
        opacity: 1
  &:hover
    color: input-color-hover
    input
      border-color: input-border-hover
      &:checked
        border-color: input-border-hover

.checkbox input:after
  arrow(primary, 2px)
  width: 9px
  border-radius: 1px
  height: 6px
  left: 6px
  top: 6px
  transform: rotate(-45deg) scale(1)

.radio input
  border-radius: 11px
  &:after
    background: primary
    content ' '
    transform: none
    display block
    width: 10px
    height @width
    border-radius: (@width / 2)
    top: (@width / 2)
    left: (@width / 2)
