.media
  &-list
    width 100%

  &-item
    color grey-darker
    > .wrapper
      height 300px
      position relative

    &:after
      content ''
      background-color rgba(black, 30%)
      position absolute
      top 65%
      left 0
      width 100%
      height 100%
      transition all 200ms ease-in-out
      z-index 1

  &-image
    position absolute
    bottom 0
    left 0
    right 0
    top 0
    z-index 1
    background-size cover
    background-position: center

  &-content
    color white
    position relative
    z-index 2
    padding 20px 5px 15px 5px
    align-self flex-end
    text-shadow 1px 1px grey-darker
    text-align left

  &-title
    color white
    font-size size-5
    letter-spacing 2px
    line-height 1.2
    margin-bottom 3px
    text-shadow 1px 1px grey-darker

  @media (min-width: 1024px)

    &-content
      padding 10px 15px

    &-title
      opacity 0
      visibility hidden
      font-size 18px
      transition all 350ms ease-out

    &-item

      &:after
        top 0px

      &:hover

        &:after
          top 65%
          background-color rgba(black, 60%)

        .media-title
          opacity 1
          visibility visible
