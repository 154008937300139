.courses
  margin-bottom 20px

  &-access
    > div
      text-align center
      border-left 1px solid grey-lighter
      border-right 1px solid grey-lighter
      border-bottom 1px solid grey-lighter

      p
        font-size 1.1em
        margin-bottom 0px !important

      a:hover
        text-decoration underline

      > *:nth-child(2n)
        font-size 1.1em
        padding 20px 15px 5px 15px

        a
          color #69707a

      > *:nth-child(2n+1)
        font-size 2em
        color #782b90

        a
          color #782b90

          &:hover
            color #782b90

      > *:first-child
        font-size 1.5em
        font-weight bold
        color white
        background-color purple

      > *:last-child
        padding-bottom 20px

  @media (min-width: 1024px)

    &-access
      display flex

      > div
        width 33%
        display flex
        flex-direction column
        justify-content space-between
