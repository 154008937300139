body.loggued-in.page-cannaseur-awards
  background-color grey-lighter

.page-cannaseur-awards, .page-cannabiz-awards

  li:first-child
    display none

  button
    color white !important
    background-color primary-awards !important
    

    &.Languages-selected
      color secondary-awards !important
      background-color primary-awards

  .site-header
    background-color primary-awards

    .site-navigation
      .navigation-item
        > a:not(.button)
          color white

        .button
          color: white;
          border-color: primary-awards

  .footer
    background-color primary-awards

  .modal
    background-color rgba(primary-awards, 85%)

  .footer-fixed
    .button
      background-color primary-awards

  #header_cannaseur
    padding 0px !important
    
    &:after
      content: '';
      position: absolute;
      top: 0px;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(primary-awards, 35%);
      z-index: 1;

    .ImageList
      margin-bottom 0px

  #content_cannaseur
    padding 0px !important
    background-color #080808 

    &:before
      content ''
      position absolute
      top -120px
      left 0
      height 20%
      width 100%
      background: primary-awards
      background: linear-gradient(#ffffff00 0%, primary-awards 25%, primary-awards 75%, #ffffff00 100%);
      z-index 3

    &:after
      content ''
      position absolute
      top 0px
      left 0
      height 100%
      width 100%
      background-image url(../images/background-cannabiz-awards.png)
      background-size cover
      z-index 2

    .wrapper
      z-index 3

      .Content
        ul
          margin 1em 2em 1em 4em
          li
            list-style-image:  url(../images/bullet-flower.png)

  @media (min-width: 1024px)

    #content_cannaseur

      &:before
        background: linear-gradient(#ffffff00 0%, primary-awards 35%, primary-awards 65%, #ffffff00 100%);
        top -180px

      .wrapper
        .Content:first-child
          margin-top -250px

  @media (max-width: 1024px)
    // only works right when max width
    .cannaseur-logo
      width 50%

.nominate
  position: absolute;
  top: 76%;
  left: 48%;
  width: 21vw;
  height: 5vw;

.vote-now
  position: absolute;
  top: 76%;
  left: 76%;
  width: 21vw;
  height: 5vw;
