
/** grid
 * Flex grid
 *
*/

// FIXME
// Hardcode para habilitar responsive en los contents. Debido a que el ancho
// está dado por estilos inline toca sobre escribir en responsive de esta forma
@media screen and (max-width: responsive-breakpoint)
  .Content, .ImageList-container
    width 100% !important

  .grid-item
    width 100% !important

.grid-item
  flex 1
  // Automatic width

$flex-item-disable
  flex none

makegrid(breakpoint=false)
  if breakpoint
    @media (min-width: breakpoint)
      display flex
      flex-flow row wrap

  else
    display flex
    flex-flow row wrap

.grid

  &:not(.grid--responsive)
    makegrid()
    .grid-item
      @extends $flex-item-disable
      &--fifth {width: 20%}
      &--quarter {width: 25%}
      &--third {width: 33.33%}
      &--half {width: 50%}

  &--responsive
    makegrid(responsive-breakpoint)
    @media (min-width: responsive-breakpoint)
      .grid-item
        @extends $flex-item-disable
        &--fifth {width: 20%}
        &--quarter {width: 25%}
        &--third {width: 33.33%}
        &--half {width: 50%}

  // Utilidades de grid
  &--expand
    justify-content space-between

  &--vertical-align
    align-items center

  &--horizontal-align
    justify-content center

  &--horizontal-end
    justify-content flex-end

  &--items
    margin-left -(column-gap)
    margin-right -(column-gap)
    margin-top -(column-gap)
    &:not(:last-child)
      margin-bottom -(column-gap)


// Compatibilidad con versiones anteriores
.grid-static
  makegrid()

.grid-fluid
  makegrid(responsive-breakpoint)

.grid-expand
  @extends .grid--expand
