.opportunity
  &-image
    position relative
    width: 80vw;
    height: 80vw;
    background-image url('/assets/images/opportunity.png')
    background-repeat no-repeat
    background-size: cover;
    background-position: 50%;
    margin auto

  .image
    position absolute
    width 20vw
    height 20vw
    transition 0.2s all ease
    img
      width 20vw
      transition 0.2s left, top ease

    &.cannabis
      top 15%
      left 14%

    &.cultivation
      top 39%
      left 1%

    &.patients
      top 62%
      left 14%

    &.transport
      top 75%
      left 39.5%

    &.labs
      top 62%
      left 64%

    &.dispensaries
      top 39%
      left 76%

    &.manufacturing
      top 15%
      left 64%

  .text
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition 0.2s all ease

    h4
      font-family 'Lato'
      color primary
      -webkit-text-stroke: 1px white;
      font-weight: bold;

  @media (min-width: responsive-breakpoint)
    &-image
      width: 45vw;
      height: 45vw;
      max-width 640px
      max-height 640px

    .image
      width 11vw
      height 11vw
      max-width 156px
      max-height 156px
      img
        width 11vw
        max-width 156px

      &:hover
        width 100%
        height 100%
        max-width initial
        max-height initial
        top 0
        left 0px

        img
          position: absolute
          top 28%
          left 36%
          width 14vw
          max-width 200px

    .text
      h4
        font-size 1.8em

    &:hover
      .text
        bottom initial
        top 60%

