.Popup
	max-width 900px !important
	padding 10px !important
	text-align center
	&-bg
		background-image url('/assets/images/bg-popup-prmedcannbiz.jpg')
		width 100%
		background-repeat no-repeat
		background-position 50% 50%
		background-size cover
		width 95vw
		height 60vw
		display flex
		align-items center

	&-content
		width 70%
		text-align center
		padding 10px

		h1, h2, h3, h4, h5
			color white
			font-family family-primary

		h2
			font-size 20px
			font-weight bold
			line-height 1

		h3
			font-size 14px
			line-height 1

		h4
			font-size 12px
			line-height 1

		h5
			font-size 11px
			line-height 1
			font-weight bold
			margin-top 5px

		.button
			margin 10px 0

	table
		max-width 550px
		margin 0 auto
		tr
			line-height 1
			border-bottom 7px solid transparent
			td:nth-child(2)
				min-width 55px

	@media (min-width: 450px)
		&-content
			h2
				font-size 2em

			h3
				font-size 1.4em

			h4
				font-size 1.2em

			h5
				font-size 1.2em

			h4:last-child
				margin-bottom 20px

	@media (min-width: 1000px)
		&-bg
			width 900px
			height 600px

		&-content
			width 60%

			h2
				font-size 3.2em

			h3
				font-size 2em

			h4, h5
				font-size 1.5em

			h4:last-child
				margin-bottom 20px

			.button
				height 52px
				margin 25px 0 35px 0

.popupS
	&-open
		background-color transparent !important
		box-shadow none !important
		margin 0px !important
	&-layer
		background-color transparent !important
	&-close
		border 1px solid white
		border-radius 50%
		width 32px !important
		height 32px !important
		color white
		background-color rgba(black, 80%)
		line-height 27px !important

