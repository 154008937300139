.new
  &-list
    width 100%
    h3
      font-size 20px
      line-height 1
      padding 10px 0
      a
        font-weight 400
        color secondary

    .ImageList
      margin-bottom 0px

  &-item
    border-bottom 0px !important
    width 100%
    padding 15px

  &-content
    p
      color secondary
      font-weight 300
      font-size 18px

  &-footer
    float right
    font-weight 600
    a
      color secondary

  @media (min-width: 1024px)
    &-item
      padding 20px
