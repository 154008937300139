
.product-amount,
.product-title
  font-weight 800

.product-title
  font-size 1.1rem

.product-amount
  color green

.product-description
  font-size .9rem
