
@keyframes modal-fade-in
  from
    opacity 0
    transform translate3d(0,0,0) scale(1.5)
  to
    transform scale(1)
    opacity 1

.modal
  position fixed
  top 0
  bottom 0
  left 0
  width 88%
  background-color rgba(primary, 85%)
  color white
  z-index 10001
  display none
  > .wrapper
    padding 60px 6% 10%
    absolute top 0 bottom 0
    left 0
    right 0
    overflow-y scroll
  h3
    font-size 2.2rem
    margin-bottom 10px
  a
    color white
    &:hover
      text-decoration underline
  .close
    color black
    background white
    position absolute
    right 4%
    top 20px
    display: flex;
    align-items: center;
    &:hover
      color darken(white, 15%)
  &.in
    display block
    animation modal-fade-in 70ms ease-in
  &.out
    display none

  input[type=number]
    max-width inherit
    margin-left 0
    text-align left

  &--full-width
    width 100%
    .modal-content
      max-width 900px
      margin 0 auto

  &--white
    background-color rgba(white, .95)
    color text
    width 100%
    h3 {color: primary}
    h4 {color: green; font-size: 1.3rem}

    .condiciones
      max-width: 1080px;
      margin: auto;
      p
        color grey-dark
        font-size size-7
    .conditions
      max-width: 1080px;
      margin: auto;
      p
        color rgb(137, 147, 162)
        font-size size-7

    .medical-conditions
      padding 10px
      ul
        list-style initial
        padding 12px

        li
          font-size 18px

.modal-content
  position relative

#chit-chat
  .wrapper
    padding 7% 6% 7%
  .Contact-model_content
    font-size 1.3rem
    @media (min-width: 700px)
      display flex
      flex-flow row wrap
      justify-content space-around
      .Modal_reach
        width 45%
        margin-right 2%
        order 1
      .Modal_form
        width 53%
        order 2

  .Modal_reach
    text-align center
    .social a
      display block
      margin-bottom 18px
      font-size 1.5rem

  .fa
    vertical-align initial

  .cognito
    iframe
      min-height 500px

  @media (min-width responsive-breakpoint)
    .Modal_reach
      text-align initial
