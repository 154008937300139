.newsletter
  display flex
  align-items center
  width 320px
  padding-top 5px
  position: relative
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .button
    border: solid 1px #FA5300;
    padding: 6px 10px;
    background-color: #FA5300;
    width: 170px;
    color: white;
    text-align center
    font-size 14px
    border-radius 12px
    height initial
    line-height initial
    transition 0.5s ease all

    &:hover
      border-color #c54302
      background-color #d84c07

  .wrapper

    > div:first-child
      text-align left
      display flex
      align-items center
      justify-content center
      padding 10px

      h2
        color white
        z-index 2
        line-height 1
        position relative
        font-weight 900
        margin 0px 0px 5px
        font-size 14px

      h3
        font-weight bold
        color white
        line-height 1
        margin-bottom 5px
        font-size 14px

      p
        color white
        line-height 1
        margin 5px 0px
        font-size 14px

    > div:last-child
      background-color #EBECDA

      h3
        font-size 1em
        color #83837D
        font-weight bold

  &-left
    padding 10px
    background-color rgba(#00947f, 90%)
    height 100%
    text-align center

  &-right
    padding 5px
    height: 450px;

  &-logo
    background-color #00947f
    padding 10px 0px

    .white-img
      filter brightness(0) invert(1)

    img
      width 20%

  .cognito
    margin-top 0px !important

    iframe
      height: 450px;

    .c-field
      padding 3px 0

    .c-button
      font-weight bold !important

  @media (min-width: 360px)
    width 350px

  @media (min-width: 800px)
    width 620px
    height 600px
    padding 0px

    .wrapper
      width 100%

      > div:first-child

        h2
          font-size 1.2em
          margin 0px 0px 10px

        h3
          font-size 1.2em

        p
          margin 7px 0px

    &-left
      padding 20px 10px 20px 25px

    &-right
      padding 20px
      height 100%

    .cognito
      margin-top 25px

      iframe
        height 360px

      .c-field
        padding 8px 0

  @media (min-width: 1024px)
    width 620px
    height 600px

    .button
      width 220px
      font-size: 18px;
      font-weight: bold;
      padding 10px

      &.small
        font-size 17px;

    .wrapper
      display flex
      align-items center
      justify-content center

      > div:first-child
        width 50%
        height 600px
        display initial
        padding 20px

        h2
          font-size 25px
          text-align left
          margin 0px 0px 12px
          line-height 1.1

        h3
          font-size 20px
          padding 5px 0px 0px
          line-height 1.1

        p
          font-size 17px
          margin 20px 0px
          line-height 1.1

      > div:last-child
        width 50%
        height 600px

        img
          padding 20px 20px 0px
          display initial
          height 100% !important

        h3
          font-size 1.5em
          padding 0px
          margin 10px 0px

    &-left
      text-align left

    &-logo
      padding 25px 0px

      img
        width auto

    .cognito.c-span-24.c-span-6
      width 50% !important
