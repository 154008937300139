.button
  @extends $input-control
  padding 4px 20px
  text-align center
  white-space nowrap
  height: 46px;
  line-height: 37px;
  border-radius: 10px
  font-weight 700
  strong
    color: inherit
  small
    display: block
    font-size: size-small
    line-height: 1
    margin-top: 5px
  .fa
    line-height: 24px
    margin: 0 -2px
    width: 24px
  &:active
    box-shadow: inset 0 1px 2px rgba(black, 0.2)

  @media (min-width: 1024px)
    font-size 20px
    padding 4px 40px

  @media (min-width: 1400px)
    padding 4px 50px

button,
input[type="submit"]
  @extends .button

.button--ghost
  border 1px solid white
  background transparent
  color white
  &:hover,
  &:focus
    color white
    border-color white

  &--blue
    color blue-dark
    border-color blue-dark

    &:hover,
    &:focus
      color blue-dark
      border-color blue-dark

  &--primary
    color primary
    border-color primary

    &:hover,
    &:focus
      color primary
      border-color primary
      background-color grey-white

  &--big
    border 2px solid #39b54a
    border-radius 10px
    font-weight 100
    font-size 0.8em
    padding 5px 20px
    &:hover,
    &:focus
      background-color rgba(purple, 30%)
      border 2px solid #39b54a !important

  @media (min-width: 450px) && (max-width: 640px)
    &--big
      font-size 1.5em

  @media (min-width: 641px)
    &--big
      font-size 2.5em

// Button colors
.button
  for c in button-colors
    name = unquote(c.name)
    color = c.color
    bg = c.bg
    &--{name}:not(.button--ghost)
      color color
      background bg
      border-color transparent

// Buttons hovers hovers
.button
  for c in button-colors
    name = unquote(c.name)
    color = c.color
    bg = c.bg
    &--{name}
      &:hover,
      &:focus
        background darken(bg, 16%)
        color color
        border-color transparent

    &--{name}-shadow
      box-shadow: 2px 2px darken(bg, 35%)

    &--primary-big
      color: white;
      border-color: white !important
      height initial
      padding 2px 8px
      font-size 16px
      font-weight 100
      line-height 28px
      &:hover,
      &:focus
        color white
        border-color transparent

    &--small
      padding 0px 12px
      height 30px
      margin 3px
      line-height: 30px;

    &--smaller
      padding: 0px 7px;
      height: 25px;
      margin: 3px;
      line-height: 22px;
      font-size: 12px;
      border-radius: 5px

    @media (min-width: 700px)
      &--primary-big
        font-size 18px
        padding 5px 8px

    @media (min-width: 900px)
      &--primary-big
        font-size 22px
        line-height 30px
        height 35px

    @media (min-width: 1300px)
      &--primary-big
        padding 12px 8px
        font-size 34px
        height 48px
