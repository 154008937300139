.Languages
  width initial
  position absolute
  top 2px
  right 8px

  li:first-child
    display none

  button
    color primary !important
    background-color transparent
    padding 2px 11px !important
    font-weight bold
    font-size 14px
    border-radius 8px

    &.Languages-selected
      color white !important
      background-color purple

  @media (min-width: 1300px)
    position relative
    margin-left 15px
    margin-right 30px
    top initial
    right initial
