.speaker-list
  width 100%

.speaker-item
  color grey-darker
  > .wrapper
    height 300px
    position relative

.speaker-image
  position absolute
  bottom 0
  left 0
  right 0
  top 0
  z-index 1
  opacity .7
  transition opacity 200ms ease-in-out
  background-size cover

.speaker-content
  position relative
  z-index 2
  padding 20px 5px 15px 5px
  align-self flex-end

.speaker-title
  color grey-darker
  font-size size-5
  letter-spacing 2px
  text-transform uppercase
  line-height 1.2
  margin-bottom 3px

.speaker-resume
  line-height 1.2

.powered-by
  position absolute
  text-align center
  right 0px
  bottom 2px
  z-index 1
  color grey
  display: flex
  align-items: center

  span
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right 3px

  .cannaworks
    height: 35px;
    width: 160px;
    background-image url('/media/imagenes/logosvariantescannaworks-02-web_5a0h5tS.png')

  .prmedcann
    height: 45px;
    width: 117px;
    background-image url('/media/archivos/consulting-firm.png')

  .prmca
    height: 35px;
    width: 130px;
    background-image url('/media/imagenes/logo_prmca.png')

  .complysquad
    height: 29px;
    width: 130px;
    background-image url('/media/imagenes/Logo.png')

  .natural
    height: 60px;
    width: 124px;
    background-image url('/media/imagenes/natural-ventures.png')

  .incredibles
    height: 31px;
    width: 150px;
    background-image url('/media/imagenes/Incredibles-logo-WHITEBG_opt.png')

  .first
    height: 41px;
    width: 120px;
    background-image url('/media/imagenes/first-medical.png')

  .nextgen
    height: 41px;
    width: 120px;
    background-image url('/media/imagenes/nextgen.png')

  .thc
    height: 50px;
    width: 100px;
    background-image url('/media/imagenes/the-health-clinic.png')

  .isodiol
    height: 51px;
    width: 91px;
    background-image url('/media/imagenes/isodiol-pr.png')

  .encanto
    height: 35px;
    width: 138px;
    background-image url('/assets/images/encantotree-diamond.png')

  .prich
    height: 51px;
    width: 56px;
    background-image url('/media/imagenes/prich.png')

  .novacann
    height: 33px;
    width: 118px;
    background-image url('/media/imagenes/novacann-labs.png')

  .hemp
    height: 66px;
    width: 66px;
    background-image url('https://www.hempinc.com/wp-content/uploads/2017/11/hempinc_logo-e1509058763477-66x66.png')

  .core
    height: 37px;
    width: 120px;
    background-image url('/media/imagenes/the-core-naturals.png')

  .knox
    height: 37px;
    width: 120px;
    background-image url('/media/archivos/knox.png')

  .anani
    height: 60px;
    width: 60px;
    background-image url('/media/archivos/medical-anani.png')

  .aarp
    height: 35px;
    width: 135px;
    background-image url('/media/archivos/AARP.png')

  .cannaseur
    height: 61px;
    width: 110px;
    background-image url('/media/archivos/cannaseur.png')

  .free_juana
    height: 51px;
    width: 90px;
    background-image: url('/media/imagenes/freejuana.png');

  .cannapreneurs
    height: 65px;
    width: 65px;
    background-image: url('/media/imagenes/cannapreneurs.png');

  .samadhi
    height: 28px;
    width: 115px;
    background-image: url('/media/imagenes/samadhi.png');

  .bwell
    height: 65px;
    width: 60px;
    background-image: url('/media/imagenes/bwell.png');

  .clinica_verde
    height: 65px;
    width: 65px;
    background-image: url('/media/imagenes/clinica_verde.png');

  .freeway
    height: 62px;
    width: 62px;
    background-image url('/media/imagenes/mj-freeway.png')

  .tropizen
    height: 54px;
    width: 72px;
    background-image: url('/media/imagenes/tropizen.png');

  .teksol
    height: 50px;
    width: 75px;
    background-image: url('/media/imagenes/tecsol.png');

  .groadvisor
    height: 52px;
    width: 80px;
    background-image: url('/media/imagenes/groadvisor.png');

  .biocann
    height: 36px;
    width: 120px;
    background-image: url('/media/imagenes/bio-cann.png');

  @media (min-width: responsive-breakpoint)
    right 10px
    z-index inherit
    color grey-darker

    span
      margin-right 10px

    .complysquad
      height: 35px;
      width: 160px;

    .free_juana
      height: 57px;
      width: 100px;

.agenda-list
  .powered-by
    background-color transparent
    bottom 15px

@media (min-width: responsive-breakpoint)
  .speaker-resume
    opacity 0
    transform translate3d(0,100%,0)
    transition transform 500ms ease-in-out
    font-weight: bold

  .speaker-content
    padding 20px
    align-self inherit

  .speaker-title
    font-size 19px
    transform translate3d(0,80px,0)
    transition transform 350ms ease-out

  .speaker-item:hover
    .speaker-image
      opacity .4
    .speaker-resume,
    .speaker-title
      color purple
      opacity 1
      transform none
      font-weight bold

// Carousel variation
.speaker-list--carousel
  position: relative
  display: block
  z-index: 1
  .owl-stage-outer
    position: relative
    overflow: hidden
    transform: translate3d(0, 0, 0)
    .owl-stage
      position: relative
      backface-visibility: hidden
  .owl-item
    position: relative
    float: left
    min-height: 1px
    backface-visibility: hidden
    transform: translate3d(0, 0, 0)
  .owl-prev
    font-size: 0
  .owl-next
    font-size: 0
  .owl-drag .owl-item
    user-select: none
  .speaker-item > .wrapper
    width 100%
  .speaker-content
    font-size .9rem
  .speaker-title
    font-size 16px
