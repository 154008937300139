.Container.section-prmedcann

  .wrapper
    margin auto !important

  .Content
    border-radius 5px
    color white
    margin-bottom 10px

    h1, h2, h3, h4, h5, h6
      font-family family-gothamhtf-bold !important
      color white
      letter-spacing initial

      span
        font-family family-gothamhtf-bold !important

    p, a, span, strong
      color white
      font-family family-gothamhtf-book !important
      letter-spacing initial

    h1
      font-size 5vw !important
      margin 10px 0

    .button
      box-shadow 1px 3px 5px 1px rgba(black, 40%)
      font-size 1em

    &:first-child
      background-color rgba(#bf07c1, 62%)
      width 250px !important
      margin 20px auto

  @media (min-width: 420px)
    .Content
      &:first-child
        width 320px !important

  @media (min-width: responsive-breakpoint)
    .Content

      h1
        font-size 2.5em !important

      p
        font-size 1.6em

      a
        font-size 18px !important

      &:first-child
        width 430px !important

      .button
        min-width 320px


.Container.Container--carousel
  background-size contain

  .ImageList
    margin 0

  .Carousel
    .owl-wrapper-outer
      line-height 0

.Container.Container--testimonials
  background-size cover
  .wrapper
    display flex
    flex-direction column

  @media (min-width: responsive-breakpoint)
    .wrapper
      flex-direction inherit

      .Content
        order 1 !important

.Container--shop.background--brand-white-4
  background-position left 100% !important
