#agenda
  h2, h3
    margin-top 2px solid primary
    margin-bottom 2px solid primary

.agenda-list
  width 100%
  max-width 900px
  margin 0 auto

.agenda-item
  position relative
  transition background-color 200ms easing
  &.with-title
    border-bottom 1px solid darken(grey-lighter, 1%)
  &:hover
    background-color rgba(grey-lighter, 50%)

.agenda-content
  width 100%

.agenda-hour
  padding 5px
.agenda-resume
  padding 15px 5px

.agenda-hour
  width 40%
.agenda-resume
  width 60%

.agenda-resume
  p:first-child
    font-weight bold
    color green !important
  p
    strong
      color green !important

  p:nth-child(2n + 1)
    color grey-dark

  p:nth-child(2n)
    color purple

.clock
  margin-right 5px

@media (min-width: 600px)
  .agenda-resume
    width: 75%
    padding 1.5rem

  .agenda-hour
    width: 25%
    padding 5px 5px 5px 1.5rem

