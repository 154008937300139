.footer-fixed
  position fixed
  bottom 0px
  left 0px
  width 100%
  color purple
  background-color brown-lighter
  box-shadow inset 0 -2px 10px darken(brown-lighter, 20%)
  text-align center
  z-index 6
  padding 7px
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;

  h3
    color purple
    margin-right 15px

  &-events
    display flex
    align-items center
    justify-content center
    flex-flow row wrap

    a
      font-weight bold

  @media (min-width: responsive-breakpoint)
    &-events
      a
        margin 0 7px