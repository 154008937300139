
.Content .article-title
  font-size 2rem
  letter-spacing 0
  margin-bottom 5px
  color purple

.article-link
  display block
  overflow hidden

.article-item
  margin-top 0 !important
  font-size 0.93rem
  max-width 800px

.article-meta
  color lighten(text, 35%)

.article-content > table td:first-child
  display none
